import * as yup from 'yup';

import regex from 'src/utils/regex';

const mobile = {
  base: yup
    .string()
    .typeError('errorMessages.string')
};
mobile.required = 
  mobile.base
    .required('errorMessages.required')
    .matches(regex.mobilePhoneWithPlusAndSpace, 'errorMessages.mobilePhoneWithPlusAndSpace') // [+351 123456789]
  ||
  mobile.base
    .required('errorMessages.required')
    .matches(regex.nineDigits, 'errorMessages.nineDigits') // [623456789] ó [723456789]
    .test('starts-with-6', 'errorMessages.startsWith6', (value) => (value.startsWith('6') || value.startsWith('7')));
    
mobile.optional = mobile.base;

export default mobile;
